import React from 'react';

import {
  Navigate, RouteObject
} from 'react-router-dom';

import { routes as adminPanelRoutes } from '../../AdminPanel/router';
import { routes as auditManagerRoutes } from '../../AuditManager/router';
import { routes as auditReviewRoutes } from '../../AuditReview/router';
import { routes as complianceViewerRoutes } from '../../ComplianceViewer/router';
import { routes as dataScoutRoutes } from '../../DataScout/router';
import { routes as docRoutes } from '../../Documentation/router';
import { routes as fedHoundRoutes } from '../../FedHound/router';
import { routes as gatingRoutes } from '../../Gating/router';
import { routes as mockAuditRoutes } from '../../MockAudit/router';
import { routes as r2aRoutes } from '../../RightToAudit/router';
import { routes as scorecardRoutes } from '../../Scorecard/router';
import { routes as serviceWorkspaceRoutes } from '../../ServiceWorkspace/router';
import { routes as uarRoutes } from '../../UserAccessReview/router';

import Home from '../pages/Home';
import NotFound from '../pages/NotFound';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to='/application' />,
  },
  {
    path: '/application/*',
    element: <Home />,
    children: [
      ...auditReviewRoutes,
      ...auditManagerRoutes,
      ...mockAuditRoutes,
      ...adminPanelRoutes,
      ...uarRoutes,
      ...serviceWorkspaceRoutes,
      ...complianceViewerRoutes,
      ...gatingRoutes,
      ...docRoutes,
      ...fedHoundRoutes,
      ...r2aRoutes,
      ...dataScoutRoutes,
      ...scorecardRoutes,
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
