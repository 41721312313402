export enum Application {
  AuditReview = '11250882-a20d-474a-be41-16b7e29eabfb',
  AuditManager = 'd58b50ae-c90c-4337-97fd-5432b4b38b3e',
  MockAudit = 'e93bde9a-9747-4257-87cd-b2926a447b69',
  AdminPanel = '58d6d128-3531-47fd-9deb-0036c69b95b3',
  UserAccessReview = 'c08a6621-1f61-4ba6-9247-3964a8708186',
  ServiceWorkspace = 'e13b7c90-d998-48b0-bd09-7e621033f706',
  ComplianceViewer = '4cb8855c-8d3b-4c22-b165-faa6efaf1e26',
  GatingRecord = '8100403f-60a6-431b-9be0-0a5057d17717',
  Documentation = '2c0dc1e5-ed10-488a-a152-c9daa8524bc2',
  FedHound = 'f3c477d4-f7f8-4fe0-8c5d-de642626ac85',
  RightToAudit = '8b694800-69df-4c02-9a50-b67ed5ae5576',
  DataScout = '6f10c29c-8c20-4c7b-84a7-5ed33cb2e48c',
  Scorecard = '55cd5615-045c-40fe-a330-8b347d18597d'
}

export enum AuditReview {
  Evidences = '26fd861c-c19a-44f4-b87e-502640c529b4',
  Dashboard = 'b72f253d-69b7-4fa0-96c3-7e1776fa208c'
}

export enum AuditManager {
  AuditEvents = 'acbe46a3-8172-4a05-b46c-f78614fd6bc4',
  EvidenceRequests = 'd7df5668-911a-42e6-8bfb-57667510b9d4'
}

export enum MockAudit {
  Reports = 'eaf01336-ad99-4e14-90cc-b913d5f14f03',
  Dashboard = 'a70c6db9-029d-4930-8a89-a9e8664ed95e',
  NewServiceEntry = 'dfef2a0f-15b5-475e-99fa-d9120fb995a0',
  FAQ = '701e7cdb-fc87-4f0d-afa0-cdd46556b03c',
  QuarterlyServices = 'b83d48ea-6d4a-466d-ab60-af4165da516d',
  Services = '0ae99e8f-59bd-455f-9986-0a0b2a990410',
  Admin = 'c9b5b9c1-7706-436d-bafc-d037037b4920',
  AdhocDataEntry = '45bb2142-7c79-4919-a576-72fe3908f5b1',
}

export enum AdminPanel {
  Collection = '24d79e8e-55cd-4692-980e-e9fef941d595',
  AdminDashboard = '1032335c-31e8-4e14-abe8-8dcfffafb7e0',
  EnvironmentList = '4bf08ac5-d7ff-43a7-99a8-59c065e836a3',
  PipelineList = '1e6d8c74-c8b3-44f2-8eae-f690a250ccf2',
  AccessControl = 'bb39155a-6290-4f84-86d7-536e3d36650c',
  SocWorkloadService = '7626e042-1b95-439a-8c92-522d772546a0',
  DataEditor = 'f3b3b3b3-1b3b-4b3b-8b3b-3b3b3b3b3b3b',
}

export enum UserAccessReview {
  ReviewManagement = '00fda0de-f4ed-403c-8290-f460d1c2ce2d',
  ClassificationReview = '3002b1ce-7d1b-48d5-81bc-3e0c652a3fe2',
  GroupReview = '99485525-10d1-4ac7-8d4c-3fbb318939fc',
  ManagerReview = '20cdfb9b-f90c-4b69-b882-7a0e1403657c',
  ManagerReviewTriage = '61700a71-1719-4c11-b154-02847f0aef52',
  RevocationReview = '8957556a-1a19-423d-8e71-c1621b38cea6',
  ManualReview = 'f19a361e-9dea-4707-9e0d-418a335ad0ad',
  ExceptionReview = '121f4fa7-0ffa-41c8-9ba6-f5235a45c642',
  ClassificationExceptions = 'a132029b-cd43-4255-9856-5872e777b204',
  RevocationExceptions = '0531ae7d-c960-4203-8f54-6edd69879717',
  MyReviews = '34F90FC0-05F6-41CC-9923-B79287FFC530',
  Dashboard = '00704a7c-126c-42a9-a790-9a887a91a986',
  ManualReviewTriage = '70cb8b83-b15f-46f0-b1f5-bb2749a73627',
  ClassificationReviewTriage = '4f85887e-9150-4fbf-846a-e44364e59bd4',
  RevocationTriage = '8260e2bb-5135-4290-9110-a46d90c8862e'
}

export enum ServiceWorkspace {
  TaskBoard = '107d3032-7964-44c8-b65e-1b3782548368',
  ServiceBoard = '2bc154dd-b0c3-4434-827b-e6725317d0a1',
  ConMon = '1d057d96-00fb-4a76-b6ac-2c2dc4a08c24',
  OnboardingDependency = '0ca1df0c-4207-47e2-b09f-23bc458a2261',
  Subprocessor = 'fa76f60d-a851-414a-bd9f-5a1510c43b98',
  Reports = '11f91a97-9447-4aa0-8daf-8e84f6d2ec9f'
}

export enum Documentation {
  Docs = '0398da4b-0246-4663-bfff-12a2600c479e'
}

export enum ComplianceViewer {
  Deployment = '754c3f07-8595-40e7-accc-4702e925500d',
  Service = '54b05587-4bd3-4b92-bfde-7a63fb3c86d7',
  Build = '37b926fe-14bf-48aa-bb4f-7bccf526ef01'
}

export enum Gating {
  GatingRecord = '27df45fe-f362-46aa-965d-2ae2b619f56a',
  GatingRecordDetails = 'ac2daa50-a3c7-4519-b05b-7321700b336f',
  GatingRecordDetailsOverview = 'a589f100-ff5c-41df-b677-539711c65624',
  GatingRecordDetailsHistory = 'ce7c92ff-b381-482b-870a-51c3b91e7427'
}

export enum FedHound {
  FedRampRenewals = '1dac32d5-939e-4893-bcbb-b170a33032fa',
  FedRampDiscovery = '3d7fa70d-6f6d-45dc-844d-3b48a9e6ed77',
  FedRampAdmin = 'd5440e0a-5f89-46ad-b5ff-daa3301893b6',
  Dashboard = 'e2cb6f7c-3f1e-42e3-bb4a-4b9fc618e109',
  NewOnboarding = '4d8f06cb-5917-4d26-92a3-7dd734b85ae6'
}

export enum RightToAudit {
  AuditIngestion = '06cfc005-f430-4468-904a-3bbf698d462e',
}

export enum DataScout {
  Welcome = 'cc075f7e-c202-4583-a3df-b4936029982a',
  DataProfile = '5708f70e-1713-460d-a647-29b2e1df959d',
  FAQ = '43c4f350-436c-49dd-b5c1-add7df8e26cb',
  Contact = 'ca92b643-3c33-4aa7-9152-ec135bbbeed3'
}

export enum Scorecard {
  ScoreCardReport = '4ceccb6d-d905-456d-b340-8713624f5f88',
  ScoringDetailReports = '0008dfb5-3915-4fb9-90f8-0ac9e0920c49'
}
